import React from "react"
import { graphql } from "gatsby"

import '../css/featured-post.css';
import '../css/text-block.css';


const FeaturedPost = ({ post }) => {

  const { title, link, excerpt, date, featuredImage, categories } = post;

  let catList = '';
  if(categories && categories.nodes) {
    categories.nodes.map((cat, index) => {
      catList += index == 0 ? cat.name : ', '+cat.name;
    })
  }

  return (
    <div className="container blogFeatured">
      <div className="two-column fifty-fifty">
        <div className="left-col">
          { featuredImage && featuredImage.node &&
            <img src={featuredImage.node.localFile.publicURL} className="flex-img new-image resource" alt={featuredImage.node.altText} />
          }
        </div>
        <div className="right-col">
        <div className="textWrapper">
          <h3 className="super">resource center</h3>
          <h2 className="headline resource">Get the Highlights:</h2>
        </div>
        <div className="excerpt">
          <h2 className="headline">{title}</h2>
          <div dangerouslySetInnerHTML={{ __html: excerpt }}></div>
          </div>
          <a className="blogBoxLinkFeat resource" href={link}>Read More</a>


        </div>
      </div>
    </div>
  )
}

export default FeaturedPost