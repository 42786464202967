import { graphql, Link } from "gatsby"
import PropTypes from "prop-types"
import React, { Component } from "react"

import '../css/blog.css';


import Layout from "../layouts/"
import Head from "../components/head"

import Pagination from "../components/pagination"

import SectionHero from "../components/section-hero";
import Sidebar from "../components/sidebar"
import FeaturedPost from "../components/featured-post"

import placeholder from "../images/blog1.jpg";

import YoastSeoParts from '../fragments/seo';

class BlogTemplate extends Component {
  render() {
    const page = this.props.data.wpPage;
    const { seo } = page
    const posts = this.props.data.allWpPost.nodes;
    const mostRecent = this.props.data.mostRecent.nodes;
    const { selectFeatured } = this.props.data.wpPage.blogArchive;

    const { currentPage, numPages } = this.props.pageContext

    return (
      <Layout>
        <Head title={seo.title} description={seo.metaDesc} meta={seo} />
        <SectionHero section={"resource"} title={page.title}/>

        { (currentPage == 1 && selectFeatured) &&
          <FeaturedPost post={selectFeatured} />
        }

        <div className="container blogMain">
        <h2 className="headline">Our <span className="resource">Latest</span> Articles</h2>
          <div className="grid smallGrid">
            <div className="col-sm-8">
                { posts &&
                  posts.map(post => {
                    const { id, title, wpURI, excerpt, featuredImage, categories, date} = post;

                    let categoryList = '';
                    if(categories) {
                      categories.nodes.map((cat, index) => {
                        categoryList += index == 0 ? cat.name : ', '+cat.name;
                      })
                    }

                    let backgroundStyles = {'backgroundImage': 'url('+placeholder+')'}
                    if(featuredImage) {
                      backgroundStyles = {'backgroundImage' : 'url('+featuredImage.node.localFile.publicURL+')'}
                    }

                    return (
                      <div key={id} className="col-md-6">
                        <div className="blogBox">
                          <Link to={wpURI}>
                            { backgroundStyles &&
                            <div className="blogBoxImage" style={backgroundStyles}></div>
                            }
                          </Link>
                          <div className="blogBoxBody">
                            <Link className="blogBoxTitle" to={wpURI}>{title}</Link>
                            <div dangerouslySetInnerHTML={{ __html: excerpt }}></div>
                            <div>{date}</div>
                          </div>
                          <div className="blogBoxFooter">
                            <div className="grid">
                              <div className="col-sm-7">
                                <p className="blogBoxCat">{categoryList}</p>
                              </div>
                              <div className="col-sm-5">
                                <Link className="blogBoxLink" to={wpURI}>Read More</Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })
                }

                <Pagination uri={page.wpURI} currentPage={currentPage} numPages={numPages} />
            </div>
            <div className="col-sm-4">
              <Sidebar posts={mostRecent} />
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

BlogTemplate.propTypes = {
  data: PropTypes.object.isRequired,
  edges: PropTypes.array,
}

export default BlogTemplate

export const pageQuery = graphql`
  query ($featuredBlogId: String = "", $skip: Int!, $limit: Int!) {
    wpPage(
      isPostsPage: {eq: true}
    ) {
      id
      title
      wpURI
      link
      seo {
        ...seoParts
      }
      date(formatString: "MMMM DD, YYYY")
      blogArchive {
        selectFeatured {
          ... on WpPost {
            id
            title
            link
            excerpt
            categories {
              nodes {
                name
              }
            }
            date(formatString: "MMMM DD, YYYY")
            featuredImage {
              node {
                altText
                localFile {
                  publicURL
                }
              }
            }
          }
        }
      }
    }

    allWpPost (
      filter: {id: {ne: $featuredBlogId}}
      sort: {fields: date, order: DESC}
      skip: $skip
      limit: $limit
    ) {
      nodes {
        id
        title
        link
        excerpt
        wpURI
        date(formatString: "MMMM DD, YYYY")
        categories {
          nodes {
            name
          }
        }
        featuredImage {
          node {
            altText
            localFile {
              publicURL
            }
          }
        }
      }
    }

    mostRecent: allWpPost (sort: {fields: date, order: DESC}, limit: 5) {
      nodes {
        id
        title
        link
        excerpt
        date(formatString: "MMMM DD, YYYY")
        categories {
          nodes {
            name
          }
        }
        featuredImage {
          node {
            altText
            localFile {
              publicURL
            }
          }
        }
      }
    }

    relatedPosts: allWpPost (sort: {fields: date, order: DESC}, limit: 15) {
      nodes {
        id
        title
        link
        excerpt
        date(formatString: "MMMM DD, YYYY")
        categories {
          nodes {
            name
          }
        }
        featuredImage {
          node {
            altText
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
`
